@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.atom__parallax-img-container {
  position: relative;
  
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  & > .img, .img-wide {
    height: 37.5rem;
    width: 100vw;
    object-fit: cover;
    object-position: right;
    margin: 2.7rem 0;

    &[data-position="left"] {
      object-position: left;
    }

    &[data-position="center"] {
      object-position: center;
    }
  }

  & > .img {
    height: 30.5rem;
  }

  @include gt-phone {
    & > .img, .img-wide {
      height: 35rem;
      width: auto;
      margin: 10rem 0 0;
    }
  }

  @include gt-tablet {
    & > .img, .img-wide {
      height: 47rem;
    }
  }
}
