$mobileLimit: 600px;
$tabletLimit: 1080px;
$desktopLimit: 1920px;

$height13: 950px;

@mixin zIndex($layer, $level) {
  $cur: $layer * 1000 + $level;
  z-index: $cur;
}

@mixin phone {
  @media (max-width: $mobileLimit - 1) { @content; }
}
@mixin gt-phone {
  @media (min-width: $mobileLimit) { @content; }
}

@mixin tablet {
  @media (min-width: $mobileLimit) and (max-width: $tabletLimit - 1) { @content; }
}
@mixin gt-tablet {
  @media (min-width: $tabletLimit) { @content; }
}

@mixin desktop {
  @media (min-width: $tabletLimit) and (max-width: $desktopLimit - 1) { @content; }
}
@mixin gt-desktop {
  @media (min-width: $desktopLimit) { @content; }
}

@mixin mediaMax($max-width) {
  @media screen and (max-width: $max-width) {
      @content;
  }
}

@mixin mediaMin($min-width) {
  @media screen and (min-width: $min-width) {
      @content;
  }
}

@mixin mediaMaxHeight($max-height) {
  @media screen and (max-height: $max-height) {
      @content;
  }
}

@mixin mediaMinHeight($min-height) {
  @media screen and (min-height: $min-height) {
      @content;
  }
}

@mixin device($device) { // must be d or t or m
  $max-width-desktop: 1260px;
  $max-width-tablet: 615px;
  $max-width-mobile: 355px;

  @if $device == d {
      max-width: $max-width-desktop;
      width: calc(100% - 60px);
      @content;
  } 
  @else if  $device == t {
      @include mediaMax(800px) {
          max-width: $max-width-tablet;
          width: calc(100% - 20px);
          @content;
      }
  } 
  @else if  $device == m {
      @include mediaMax(600px) {
          max-width: $max-width-mobile;
          width: calc(100% - 20px);
          @content;
      }
  }
}

/* how to use mixin: 
@include device(d);
or
@include device(t) {
  // content
}
@include device(m) {
  // content
}
*/

/******************************************
 *               SPACERS                  *
 ******************************************/

$spacing-s:     1rem;
$spacing-m:     1.5rem;
$spacing-l:     2rem;
$spacing-xl:    2.5rem;
$spacing-xxl:   3rem;
$spacing-xxl-2: 4.5rem;
$spacing-xxl-3: 5rem;
